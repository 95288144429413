import { BlueprintIcon } from "@ui/BlueprintIcon";
import isNil from "lodash/isNil";

import { RequirementVerificationMethod } from "@rollup-api/models/requirementBlock";
import { IComplexSelectOption } from "@rollup-types/selection";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { isHeading } from "@store/Requirements/RequirementsStore";

export const getVerificationMethodText = (type: RequirementVerificationMethod) => {
  switch (type) {
    case RequirementVerificationMethod.Test:
      return "Test";
    case RequirementVerificationMethod.Inspection:
      return "Inspection";
    case RequirementVerificationMethod.Analysis:
      return "Analysis";
    case RequirementVerificationMethod.Demonstration:
      return "Demonstration";
    case RequirementVerificationMethod.Simulation:
      return "Simulation";
    case RequirementVerificationMethod.Sample:
      return "Sample";
  }
};

const emDashUnicode = "\u2014";

export const getLevelCellText = (level?: null | number): string => {
  return !isNil(level) && level >= 0 ? `${level}` : emDashUnicode;
};

export const mapLevelTextToNumber = (levelText: string): number | null => {
  return levelText === emDashUnicode ? null : parseInt(levelText, 10);
};

const requirementLevelOptions = [1, 2, 3, 0, null];
export const requirementLevelTextOptions = requirementLevelOptions.map(getLevelCellText);

export const getVerificationMethodIcon = (type: RequirementVerificationMethod) => {
  switch (type) {
    case RequirementVerificationMethod.Test:
      return "lab-test";
    case RequirementVerificationMethod.Inspection:
      return "form";
    case RequirementVerificationMethod.Analysis:
      return "function";
    case RequirementVerificationMethod.Demonstration:
      return "step-chart";
    case RequirementVerificationMethod.Simulation:
      return "curved-range-chart";
    case RequirementVerificationMethod.Sample:
      return "build";
  }
};

export const getTooltipContent = (type: RequirementVerificationMethod) => {
  switch (type) {
    case RequirementVerificationMethod.Inspection:
      return "Technique based on visual or dimensional examination of an element";
    case RequirementVerificationMethod.Simulation:
      return "Technique based on evidence of similar elements to the submitted element or on experience feedback";
    case RequirementVerificationMethod.Analysis:
      return "Technique based on analytical evidence obtained without any intervention on the submitted element using mathematical or probabilistic calculation";
    case RequirementVerificationMethod.Demonstration:
      return "Technique based on evidence of similar elements under defined conditions to show theoretical compliance";
    case RequirementVerificationMethod.Test:
      return "Technique performed onto the submitted element when subjected to controlled conditions";
    case RequirementVerificationMethod.Sample:
      return "Technique based on verification of characteristics using parts from the whole";
    default:
      return "";
  }
};

export const createVerificationMethodOption = (
  type: RequirementVerificationMethod
): IComplexSelectOption<RequirementVerificationMethod> => ({
  value: type,
  label: getVerificationMethodText(type),
  icon: <BlueprintIcon icon={getVerificationMethodIcon(type)} />,
  tooltip: getTooltipContent(type),
});

export const handleReqBlockClick = (reqBlock: IRequirementBlock) => {
  if (isHeading(reqBlock.type)) {
    return;
  }

  appStore.env.setActiveReqBlockId(reqBlock.id);
};
