import { useHandleDeleteDocument } from "@hooks/useHandleDeleteDocument";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { EntityContextMenu } from "@components/EntityContextMenu";
import { EPageName } from "@router/hooks/useAppNavigate";
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { EntityType } from "@store/types";

import "./CodeBlocksSidebarContextMenu.scss";

interface CodeBlocksSidebarContextMenuProps {
  codeBlock: IAnalysis;
  onEnter?(): void;
}

const CodeBlocksSidebarContextMenu = (props: CodeBlocksSidebarContextMenuProps) => {
  const { codeBlock, onEnter } = props;
  const workspace = useWorkspace();

  const handleDelete = useHandleDeleteDocument({
    document: codeBlock,
    docArray: workspace.analysis.analyses,
    pageName: EPageName.Analysis,
    deleteDocument: appStore.workspaceModel?.analysis.deleteAnalysis,
  });

  const handleUpdate = (label: string) => {
    codeBlock.setLabel(label);
  };

  return (
    <EntityContextMenu
      node={codeBlock}
      entityType={EntityType.CodeBlock}
      defaultName={codeBlock.label}
      onNameChange={handleUpdate}
      // TODO:
      // onDuplicate={handleDuplicate}
      onDelete={handleDelete}
      onEnter={onEnter}
    />
  );
};

export default observer(CodeBlocksSidebarContextMenu);
