import { DataLink, PropertyDataType, resolveEntity } from "@rollup-io/engineering";
import d2lIntl from "d2l-intl";

import { DataSource } from "@rollup-api/models/data-sources";
import { IAnalysisInput } from "@store/Analysis/AnalysisInputStore";
import { IAnalysisOutput } from "@store/Analysis/AnalysisOutputStore";
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import appStore from "@store/AppStore";
import { IBomTable } from "@store/BomTable/BomTableStore";
import { IDataSinkEntry } from "@store/DataConnection/DataSinkEntryStore";
import { IDataSink } from "@store/DataConnection/DataSinkStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IReportBlock } from "@store/ReportBlockStore";
import { IReport } from "@store/ReportsStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsStore";
import { EntityType } from "@store/types";
import { getWorkspaceById } from "@utilities/Workspace";

import { getBlockById } from "./Block";

const ScalarFormatter = new d2lIntl.NumberFormat("en-US");
export const FormatScalarValue = (value?: number) => {
  if (typeof value !== "number" || isNaN(value)) {
    return "NaN";
  }
  if (!isFinite(value)) {
    return "INF";
  }
  try {
    return ScalarFormatter.format(value);
  } catch (err) {
    return "NaN";
  }
};

export const ScalarParser = new d2lIntl.NumberParse("en-US");

export const getPropertyInstancePathByID = (id: string) => {
  const property = resolveEntity<IPropertyInstance>(appStore?.workspaceModel?.propertyInstanceMap, id);
  return property?.path ?? "";
};

export const getPropertyInstanceByIdAsync = async (id: string, workspaceId?: string): Promise<IPropertyInstance | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IPropertyInstance>(workspace?.propertyInstanceMap, id);
};

export const getPropertyInstanceById = (id: string): IPropertyInstance | undefined => {
  const property = resolveEntity<IPropertyInstance>(appStore?.workspaceModel?.propertyInstanceMap, id);
  if (property) {
    return property;
  }
  return resolveEntity<IPropertyInstance>(appStore?.temporaryWorkspace?.propertyInstanceMap, id);
};

export const getPropertyDefinitionById = (id: string) => {
  return resolveEntity<IPropertyDefinition>(appStore?.workspaceModel?.propertyDefinitionMap, id);
};

export const getDataSourceById = (id: string) => {
  return resolveEntity<DataSource>(appStore?.workspaceModel?.dataConnection.dataSourceMap, id);
};

export const getDataSinkById = (id: string) => {
  return resolveEntity<IDataSink>(appStore?.workspaceModel?.dataConnection.dataSinkMap, id);
};

export const getDataSinkEntryById = (id: string) => {
  return resolveEntity<IDataSinkEntry>(appStore?.workspaceModel?.dataConnection.dataSinkEntryMap, id);
};

export const getDataLinkById = (id: string) => {
  return resolveEntity<DataLink>(appStore?.workspaceModel?.dataConnection.dataSourceLinkMap, id);
};

export const getReportById = (id: string) => {
  return resolveEntity<IReport>(appStore?.workspaceModel?.reportsMap, id);
};

export const getKnowledgebasePageByIdAsync = async (id: string, workspaceId?: string): Promise<IReport | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IReport>(workspace?.reportsMap, id);
};

export const getReportBlockById = (id: string) => {
  return resolveEntity<IReportBlock>(appStore?.workspaceModel?.reportBlocksMap, id);
};

export const getKnowledgebaseBlockByIdAsync = async (id: string, workspaceId?: string): Promise<IReportBlock | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IReportBlock>(workspace?.reportBlocksMap, id);
};

export const getRequirementsPageById = (id: string) => {
  return appStore?.workspaceModel?.requirementsModule.get(id);
};

export const getRequirementsPageByIdAsync = async (id: string, workspaceId?: string): Promise<IRequirementsPage | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IRequirementsPage>(workspace?.requirementsModule.requirementsPageMap, id);
};

export const getRequirementBlockById = (id: string): IRequirementBlock | undefined => {
  return appStore?.workspaceModel?.requirementsModule.getBlock(id);
};

export const getRequirementBlockByIdAsync = async (id: string, workspaceId?: string): Promise<IRequirementBlock | undefined> => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IRequirementBlock>(workspace?.requirementsModule.requirementBlockMap, id);
};

export const getCodeBlockById = (id: string) => {
  return resolveEntity<IAnalysis>(appStore?.workspaceModel?.analysis?.analysisMap, id);
};

export const getAnalysisOutputById = (id: string) => {
  return resolveEntity<IAnalysisOutput>(appStore?.workspaceModel?.analysis?.analysisOutputMap, id);
};

export const getAnalysisInputById = (id: string) => {
  return resolveEntity<IAnalysisInput>(appStore?.workspaceModel?.analysis?.analysisInputMap, id);
};

export const getBomTableById = (id: string) => {
  let bomTable = resolveEntity<IBomTable>(appStore?.workspaceModel?.bomTablesMap, id);
  if (!bomTable) {
    bomTable = resolveEntity<IBomTable>(appStore?.temporaryWorkspace?.bomTablesMap, id);
  }
  return bomTable;
};

// VALUES

export const getRequirementLinkedPropertyValue = (prop: IPropertyInstance) => {
  const definition = prop?.propertyDefinition;
  if (!definition) {
    console.debug("Warning: Property " + prop.id + " has no definition.");
    return undefined;
  }

  if (definition.dataType === PropertyDataType.scalar) {
    return `${prop.numericValue ?? ""}${prop.effectiveUnit ? ` ${prop.effectiveUnit}` : ""}`;
  } else {
    console.debug("Warning: Property " + prop.id + " has unsupported data type.");
  }

  return undefined;
};

export const resolveAmongAllEntities = (id: string, entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Block:
      return getBlockById(id);
    case EntityType.BomTable:
      return getBomTableById(id);
    case EntityType.CodeBlock:
      return getCodeBlockById(id);
    case EntityType.AnalysisInput:
      return getAnalysisInputById(id);
    case EntityType.AnalysisOutput:
      return getAnalysisOutputById(id);
    case EntityType.DataLink:
      return getDataLinkById(id);
    case EntityType.DataSource:
      return getDataSourceById(id);
    case EntityType.DataSink:
      return getDataSinkById(id);
    case EntityType.DataSinkEntry:
      return getDataSinkEntryById(id);
    case EntityType.PropertyDefinition:
      return getPropertyDefinitionById(id);
    case EntityType.PropertyInstance:
      return getPropertyInstanceById(id);
    case EntityType.Report:
      return getReportById(id);
    case EntityType.ReportBlock:
      return getReportBlockById(id);
    case EntityType.RequirementsDocument:
      return getRequirementsPageById(id);
    default:
      console.error("Warning: Unsupported entity type.");
      return undefined;
  }
};

export const resolveEntityLabel = (id: string, entityType: EntityType): string | undefined => {
  const entity = resolveAmongAllEntities(id, entityType);
  if (entity && "label" in entity) {
    return entity.label as string;
  }
  return undefined;
};
