import { ReactElement } from "react";
import { IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import omit from "lodash/omit";

import { ModelingIcon, ModuleColorfulIcon, ModuleMonochromeIcon } from "@components/CustomIcon";
import { EntityIcon } from "@components/EntityIcon";
import { RemoveFavoriteButton } from "@components/RemoveFavoriteButton";
import { IconSource, IIcon } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import { INavLinkAsMenuItem } from "@router/navigation/NavLinkAsMenuItem";
import { NavLinkBackgroundColor } from "@router/navigation/NavLinkAsMenuItem.types";
import appStore from "@store/AppStore";
import { IFavoriteModule } from "@store/FavoriteModuleStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { IWorkspace } from "@store/WorkspaceStore";
import { IE2EIdentifiers, IE2EIdentifiersParam } from "@utilities/E2EUtils";
import { isFieldDefined } from "@utilities/TypeGuards";

export interface TNavigationLinkConfig extends IE2EIdentifiersParam {
  text: string;
  icon?: (IconName | ReactElement) | IIcon;
  to: string;
  nestedLinks?: Array<Omit<TNavigationLinkConfig, "nestedLinks">>;
  isDisabled?(): boolean;
}

export type TNavigationLinkConfigs = Array<TNavigationLinkConfig>;

const addPrefixToE2EIdentifiers = (e2eIdentifiers: IE2EIdentifiers, e2ePrefix?: string): IE2EIdentifiers => {
  if (e2ePrefix) {
    return [e2ePrefix, ...(Array.isArray(e2eIdentifiers) ? e2eIdentifiers : [e2eIdentifiers])];
  }
  return e2eIdentifiers;
};

const getBlockNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Blocks",
  icon: { source: IconSource.Custom, name: ModelingIcon.BlockTree },
  to: EPageName.Blocks,
  e2eIdentifiers: addPrefixToE2EIdentifiers("blocks", e2ePrefix),
});

const getTableNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Table",
  icon: { source: IconSource.Custom, name: ModelingIcon.Table },
  to: EPageName.Table,
  e2eIdentifiers: addPrefixToE2EIdentifiers("table", e2ePrefix),
});

const getPropertiesNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Properties",
  icon: { source: IconSource.Custom, name: ModelingIcon.PropertyDefinitions },
  to: EPageName.Properties,
  e2eIdentifiers: addPrefixToE2EIdentifiers("properties", e2ePrefix),
});

const getStatusesNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Statuses",
  icon: { source: IconSource.Custom, name: ModelingIcon.StatusDefinitions },
  to: EPageName.Statuses,
  e2eIdentifiers: addPrefixToE2EIdentifiers("statuses", e2ePrefix),
});

const getCustomUnitsNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Custom Units",
  icon: { source: IconSource.Custom, name: ModelingIcon.CustomUnits },
  to: EPageName.CustomUnits,
  e2eIdentifiers: addPrefixToE2EIdentifiers("statuses", e2ePrefix),
  isDisabled: () => !appStore.env.featureFlags.enabled(FeatureFlag.CUSTOM_UNITS),
});

const getModelConfigurationNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Model Configuration",
  icon: { source: IconSource.Custom, name: ModelingIcon.ModelConfiguration },
  to: "#",
  nestedLinks: [getPropertiesNavItem(e2ePrefix), getStatusesNavItem(e2ePrefix), getCustomUnitsNavItem(e2ePrefix)],
  e2eIdentifiers: addPrefixToE2EIdentifiers("model-configuration", e2ePrefix),
});

const getDagNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Property Relations",
  icon: { source: IconSource.Custom, name: ModelingIcon.PropertyRelations },
  to: `${EPageName.Threads}/${EPageName.PropertyRelations}`,
  e2eIdentifiers: addPrefixToE2EIdentifiers("property-relations", e2ePrefix),
});

const getHierarchyNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Hierarchy",
  icon: { source: IconSource.Custom, name: ModelingIcon.Hierarchy },
  to: `${EPageName.Threads}/${EPageName.Hierarchy}`,
  e2eIdentifiers: addPrefixToE2EIdentifiers("hierarchy", e2ePrefix),
});

const getThreadsNavItem = (e2ePrefix?: string): TNavigationLinkConfig => ({
  text: "Digital Threads",
  icon: { source: IconSource.Custom, name: ModelingIcon.DigitalThreads },
  to: EPageName.Threads,
  nestedLinks: [getDagNavItem(e2ePrefix), getHierarchyNavItem(e2ePrefix)],
  e2eIdentifiers: addPrefixToE2EIdentifiers("threads", e2ePrefix),
});

const removeDisabledMenuItems = (menuItems: TNavigationLinkConfigs): TNavigationLinkConfigs => {
  return menuItems
    .filter(item => !item.isDisabled?.())
    .map(item => {
      return {
        ...omit(item, "isDisabled"),
        ...(item.nestedLinks && {
          nestedLinks: item.nestedLinks.filter(nestedItem => !nestedItem.isDisabled?.()).map(i => omit(i, "isDisabled")),
        }),
      };
    });
};

export const getModelingLayoutMenuItems = (e2ePrefix = ""): TNavigationLinkConfigs => {
  const menuItems = [
    getBlockNavItem(e2ePrefix),
    getTableNavItem(e2ePrefix),
    getThreadsNavItem(e2ePrefix),
    getModelConfigurationNavItem(e2ePrefix),
  ];
  return removeDisabledMenuItems(menuItems);
};

export const getHardwareCatalogLayoutMenuItems = (e2ePrefix?: string): TNavigationLinkConfigs => {
  const menuItems: TNavigationLinkConfigs = [
    {
      text: "Item catalog",
      icon: { source: IconSource.Custom, name: ModuleMonochromeIcon.ItemCatalog },
      to: EPageName.ItemCatalog,
      e2eIdentifiers: addPrefixToE2EIdentifiers("item-catalog", e2ePrefix),
    },
  ];

  if (appStore.env.featureFlags.enabled(FeatureFlag.BOM)) {
    menuItems.push({
      text: "BOM",
      icon: { source: IconSource.Custom, name: ModuleMonochromeIcon.Bom },
      to: EPageName.Bom,
      e2eIdentifiers: addPrefixToE2EIdentifiers("bom", e2ePrefix),
    });
  }

  menuItems.push({
    text: "PDM Configuration",
    icon: { source: IconSource.Blueprint, name: IconNames.Cog },
    to: EPageName.Settings,
    e2eIdentifiers: addPrefixToE2EIdentifiers("pdm-config", e2ePrefix),
  });

  return menuItems;
};

const getPageRootUrl = (page: string) => {
  if (appStore.workspaceModel?.id) {
    return `/workspaces/${appStore.workspaceModel?.id}/${page}`;
  }

  return page;
};

const getFavoritesNavMenuItem = (params: {
  favorites: IFavoriteModule;
  isSidebarOpen: boolean;
  toggleSidebar(): void;
}): INavLinkAsMenuItem => {
  const { favorites, isSidebarOpen, toggleSidebar } = params;

  const handleToggleExpand = () => {
    if (isSidebarOpen) {
      appStore.env.toggleFavoritesExpanded();
      return;
    }
    toggleSidebar();
    if (!appStore.env.isFavoritesExpanded) {
      appStore.env.toggleFavoritesExpanded();
    }
  };

  return {
    text: "Favorites",
    icon: IconNames.Star,
    to: "#",
    nestedLinks: favorites.aliveFavorites.filter(isFieldDefined("entityTypePage")).map(favorite => {
      const isActive = favorite.isActive(window.location.pathname);
      return {
        to: `${getPageRootUrl(favorite.entityTypePage)}/${favorite.entityId}`,
        selected: isActive,
        text: favorite.label,
        e2eIdentifiers: [`favorite-${favorite.label}`],
        icon: <EntityIcon id={favorite.entityId} entityType={favorite.entityType} active={isActive} />,
        renderRightElements: (className: string) => (
          <RemoveFavoriteButton favorite={favorite} className={className} onRemoveFavorite={favorites.removeFavorite} />
        ),
      };
    }),
    expandedInitialState: appStore.env.isFavoritesExpanded,
    onToggleExpand: handleToggleExpand,
    dividerBelow: true,
    collapseIfSidebarCollapsed: true,
    e2eIdentifiers: ["favorites"],
  };
};

interface IGetMainNavMenuItemsParams {
  workspace: IWorkspace | null;
  isSidebarOpen: boolean;
  currentPage?: string;
  toggleSidebar(): void;
}

export const getMainNavMenuItems = (params: IGetMainNavMenuItemsParams): INavLinkAsMenuItem[] => {
  const { workspace, currentPage, isSidebarOpen, toggleSidebar } = params;

  return [
    {
      text: "Inbox",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Inbox },
      to: `/${EPageName.Inbox}?view=inbox${
        appStore.env.inboxSettings.selectedWorkspaceId && `&ws=${appStore.env.inboxSettings.selectedWorkspaceId}`
      }`,
      activeBgColor: NavLinkBackgroundColor.Green,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Inbox,
      tooltip: { title: "Inbox", content: "Read and respond to mentions and notifications" },
      dividerBelow: true,
      e2eIdentifiers: ["inbox"],
    },
    ...(workspace ? [getFavoritesNavMenuItem({ favorites: workspace.favorites, isSidebarOpen, toggleSidebar })] : []),
    {
      text: "Modeling",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Modeling },
      to: getPageRootUrl(EPageName.Modeling),
      activeBgColor: NavLinkBackgroundColor.Red,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Modeling,
      tooltip: { title: "Modeling", content: "System modeling with blocks and properties" },
      e2eIdentifiers: ["modeling"],
    },
    {
      text: "Requirements",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Requirements },
      to: getPageRootUrl(EPageName.Requirements),
      activeBgColor: NavLinkBackgroundColor.Indigo,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Requirements,
      tooltip: "Requirements",
      e2eIdentifiers: ["requirements"],
    },
    {
      text: "Analysis",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Analysis },
      to: getPageRootUrl(EPageName.Analysis),
      activeBgColor: NavLinkBackgroundColor.Lime,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Analysis,
      tooltip: { title: "Analysis", content: "Create and run analyses" },
      e2eIdentifiers: ["analysis"],
    },
    {
      text: "Knowledgebase",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.Knowledgebase },
      to: getPageRootUrl(EPageName.Knowledgebase),
      activeBgColor: NavLinkBackgroundColor.Cerulean,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Knowledgebase,
      tooltip: "Knowledgebase",
      e2eIdentifiers: ["knowledgebase"],
    },
    {
      text: "Product Data Management",
      icon: { source: IconSource.Custom, name: ModuleColorfulIcon.HardwareCatalog },
      to: getPageRootUrl(EPageName.Pdm),
      activeBgColor: NavLinkBackgroundColor.Gray,
      showInactiveBgColor: true,
      selected: currentPage === EPageName.Pdm,
      tooltip: "Product Data Management",
      e2eIdentifiers: ["hardware-catalog"],
    },
  ];
};
