import { useEffect, useState } from "react";
import { Divider, NonIdealState, NonIdealStateIconSize } from "@blueprintjs/core";
import { useSearchParam } from "@hooks/useSearchParam/useSearchParam";
import { observer } from "mobx-react";

import AnnotationThumbnailPreview from "@components/AnnotationThumbnailPreview/AnnotationThumbnailPreview";
import { Button } from "@components/Button";
import RollupEventContextMenu from "@components/Inbox/RollupEventContextMenu";
import { CommentThreadDisplayStyle } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThread";
import CommentThreadList from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThreadList";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import { Popover } from "@components/Popover";
import { RelativeDateWithTooltip } from "@components/RelativeDateWithTooltip";
import UserInfo from "@components/UserInfo/UserInfo";
import { TemporalDirection } from "@rollup-api/models/comments/commentGetThreadedRequestDto.model";
import { Profile } from "@rollup-api/models/profiles";
import { IIcon } from "@rollup-types/icons";
import { useAppNavigate } from "@router/hooks";
import appStore from "@store/AppStore";
import { CommentDisplayParentType } from "@store/CommentStore";
import { IRollupEvent, RollupEventLocations } from "@store/RollupEventsStore";
import { getParentNameAndIcon } from "@utilities/Inbox";
import { Text, TextVariant } from "src/ui/Text";

import { EventParentIcon } from "../EventParentIcon";

import "./RollupEventReadingPane.scss";

interface RollupEventReadingPaneProps {
  onMarkAs: () => void;
  onMove: () => void;
}

const RollupEventReadingPane = ({ onMarkAs, onMove }: RollupEventReadingPaneProps) => {
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [subjectProfile, setSubjectProfile] = useState<Profile | null>(null);
  const [parentName, setParentName] = useState("");
  const [parentIcon, setParentIcon] = useState<IIcon | string>();
  const { navigateToDiscussionFromDisplayParent } = useAppNavigate();

  const [rollupEventIdParam] = useSearchParam("msg");
  const [workspaceIdParam] = useSearchParam("ws");

  const { info } = appStore.orgModel;

  const rollupEvent = appStore.userModel?.rollupEvents?.eventWithId(rollupEventIdParam);

  // TODO: Reconsider this logic with the subscriptions. They won't always have comment threads.
  // set the rollup event and comment thread
  useEffect(() => {
    if (!rollupEvent) {
      return;
    }
    if (!rollupEvent.commentThreadList.threads.length && rollupEvent.metadata.mentionEntityType === RollupEventLocations.comment) {
      // fetch the comment thread if the mention is in a comment and it's comment thread is not already fetched
      rollupEvent.commentThreadList.fetchThreadList({
        initialFetch: true,
        temporalDirection: TemporalDirection.Both,
        focusedCommentId: rollupEvent.metadata.parentCommentId ?? rollupEvent.metadata.commentId,
        type: getCommentThreadFetchType(rollupEvent.metadata.displayParentType),
        parentId: rollupEvent.metadata.parentId,
      });
    }
  }, [rollupEventIdParam, workspaceIdParam, rollupEvent]);

  // TODO: Reconsider this logic with the subscriptions. They won't always have subjects which are users.
  // set the user and subject profiles
  useEffect(() => {
    const getAndSetUserProfile = async (user: string | undefined) => {
      if (user) {
        const userProfile: Profile | null = await appStore.orgModel?.info?.getUserProfile(user);
        setUserProfile(userProfile ?? null);
      }
    };
    // below function is currently the same as above, but in the future once we have different subject they will start to differ
    const getAndSetSubjectProfile = async (user: string | undefined) => {
      if (user) {
        const userProfile: Profile | null = await appStore.orgModel?.info?.getUserProfile(user);
        setSubjectProfile(userProfile ?? null);
      }
    };

    if (!rollupEvent) {
      return;
    }
    // if members are loaded in the store, get and set the user and subject profiles
    getAndSetUserProfile(rollupEvent.createdBy);
    getAndSetSubjectProfile(rollupEvent.metadata?.subject?.[0]);
  }, [rollupEvent, info.memberCount]);

  // Set parent name and icon to display at the top of the reading pane.
  useEffect(() => {
    if (!rollupEvent) {
      return;
    }
    switch (rollupEvent.metadata.mentionEntityType) {
      case RollupEventLocations.report:
      case RollupEventLocations.comment:
        if (!rollupEvent.metadata.parentId || !rollupEvent.metadata.parentType) {
          break;
        }
        getParentNameAndIcon(rollupEvent.metadata.parentType, rollupEvent.metadata.parentId, rollupEvent.workspaceId).then(
          parentNameAndIcon => {
            setParentName(parentNameAndIcon.parentName);
            setParentIcon(parentNameAndIcon.parentIcon);
          }
        );

        break;
      case RollupEventLocations.block:
        // TODO: implement. try to reuse the above function setParentNameAndIcon also for these cases.
        break;
      default:
        console.warn("Unknown mention entity type", rollupEvent.metadata.mentionEntityType);
    }
  }, [rollupEvent]);

  // do not render if there is nothing to render
  if (!rollupEvent) {
    return (
      <NonIdealState
        icon="inbox"
        iconSize={NonIdealStateIconSize.EXTRA_SMALL}
        title="Select ticket"
        description="Click on the ticket to see the details"
      />
    );
  }

  const getCommentThreadFetchType = (displayParentType: CommentDisplayParentType | undefined): CommentLocationType => {
    if (displayParentType === CommentDisplayParentType.Annotation) {
      return CommentLocationType.Annotation;
    }
    return CommentLocationType.Root;
  };

  const getEventActionText = (rollupEvent: IRollupEvent): string => {
    switch (rollupEvent.entityType) {
      case "comment":
        return "mentioned";
      default:
        return "acted on ";
    }
  };

  // TODO: change according to new event location logic
  const handleNavigateToDiscussion = () => {
    switch (rollupEvent.metadata.mentionEntityType) {
      case RollupEventLocations.report:
      case RollupEventLocations.comment:
        if (!rollupEvent.metadata.displayParentId || !rollupEvent.metadata.displayParentType || !rollupEvent.workspace) {
          setParentName("");
          break;
        }
        navigateToDiscussionFromDisplayParent(
          rollupEvent.metadata.displayParentType,
          rollupEvent.metadata.displayParentId,
          rollupEvent.workspace
        );
        break;
      case RollupEventLocations.block:
        // TODO: navigate to the related entity of the block or report (see if able to use above navigate function)
        break;
      default:
        console.warn("Unknown mention entity type", rollupEvent.metadata.mentionEntityType);
    }
  };

  return (
    <div className="reading-pane--wrapper">
      <div className="reading-pane">
        <div className="reading-pane--header">
          {parentIcon && <EventParentIcon type={rollupEvent.metadata.parentType} icon={parentIcon} />}
          <div>
            <Text variant={TextVariant.H1}>{parentName}</Text>
            <div className="reading-pane--header--workspace-time">
              <Text variant={TextVariant.Label} color="var(--text-caption-color)">
                {rollupEvent.metadata?.workspaceLabel}
              </Text>
              <Text variant={TextVariant.Label} color="var(--text-caption-color)">
                •
              </Text>
              <RelativeDateWithTooltip variant={TextVariant.Caption} epochTime={rollupEvent.createdAt} />
            </div>
          </div>
          <div className="reading-pane--header--context-menu">
            <Button icon="share" text="Go to discussion" minimal e2eIdentifiers="go-to-page" onClick={handleNavigateToDiscussion} />
            <Popover
              placement="bottom-end"
              content={<RollupEventContextMenu rollupEvent={rollupEvent} onMarkAs={onMarkAs} onMove={onMove} />}
            >
              <Button icon="more" minimal e2eIdentifiers="more" />
            </Popover>
          </div>
        </div>
        <div className="reading-pane--description">
          <div className="reading-pane--description--content">
            <UserInfo userName={userProfile?.name} avatarUrl={userProfile?.avatarUrl} size="small" />
            <Text variant={TextVariant.BodyDimmed} color="">
              {getEventActionText(rollupEvent)}
            </Text>
            <UserInfo userName={subjectProfile?.name} avatarUrl={subjectProfile?.avatarUrl} size="small" />
            <Text variant={TextVariant.BodyDimmed}>in</Text>
            <Text variant={TextVariant.H4}>{parentName} </Text>
          </div>
        </div>
        <Divider />
        {rollupEvent.metadata?.mentionEntityType === RollupEventLocations.comment && (
          <>
            {rollupEvent.metadata.displayParentType === CommentDisplayParentType.Annotation &&
              rollupEvent.metadata.displayParentId &&
              rollupEvent.commentThreadList.threads[0]?.parentComment && (
                <AnnotationThumbnailPreview
                  annotationId={rollupEvent.metadata.displayParentId}
                  comment={rollupEvent.commentThreadList.threads[0].parentComment}
                />
              )}
            <div className="reading-pane--activity">
              <CommentThreadList
                commentThreadList={rollupEvent.commentThreadList}
                showOlderThreads
                showNewerThreads
                type={getCommentThreadFetchType(rollupEvent.metadata.displayParentType)}
                parentId={rollupEvent.metadata.parentId}
                displayStyle={CommentThreadDisplayStyle.Panel}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(RollupEventReadingPane);
