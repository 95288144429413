import { useState } from "react";
import { PopoverPosition } from "@blueprintjs/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import classNames from "classnames";
import { observer } from "mobx-react";

import { EntityAnnotationListPopover } from "@components/EntityAnnotationListPopover";
import { handleReqBlockClick } from "@components/Requirements/Page/RequirementPageUtils";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage, isHeading } from "@store/Requirements/RequirementsStore";
import { StoreType } from "@store/types";

import { RequirementDocumentBlockImageRow } from "../RequirementDocumentBlockImageRow";
import { RequirementDocumentBlockMainRow } from "../RequirementDocumentBlockMainRow";
import { RequirementDocumentBlockNoteRow } from "../RequirementDocumentBlockNoteRow";

import styles from "./RequirementsDocumentBlock.module.scss";

interface IRequirementsDocumentBlockProps {
  reqBlock: IRequirementBlock;
  reqPage: IRequirementsPage;
  dragListeners: SyntheticListenerMap;
  editable?: boolean;
  onFocus(): void;
}

const RequirementsDocumentBlock = (props: IRequirementsDocumentBlockProps) => {
  const { reqBlock, dragListeners, reqPage, onFocus } = props;
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isAddingImage, setIsAddingImage] = useState(false);
  const [isCommentPopoverOpen, setIsCommentPopoverOpen] = useState(false);
  const showNote = isAddingNote || reqBlock.note;
  const showImage = isAddingImage || reqBlock.imageUrl;
  const { hoveredCommentAnnotationId } = appStore.ui;
  const isSelected = appStore.ui.selectedIds.includes(reqBlock.id);

  return (
    <EntityAnnotationListPopover
      className="report-block--popover-target"
      annotationList={reqBlock.annotationList}
      entityId={reqBlock.id}
      entityIdsWithComments={reqPage.getReqBlockIdsWithComments()}
      storeType={StoreType.RequirementBlock}
      isOpen={isCommentPopoverOpen}
      onInteraction={setIsCommentPopoverOpen}
      popoverProps={{ position: PopoverPosition.BOTTOM }}
      disableOpenOnTargetClick
    >
      <div
        className={classNames(styles.requirementsDocumentBlock, {
          [styles.requirementsDocumentBlockHeading]: isHeading(reqBlock.type),
          [styles.hasComment]: reqBlock.hasComments,
          [styles.highlighted]: hoveredCommentAnnotationId && hoveredCommentAnnotationId === reqBlock.annotationList.annotations[0]?.id,
          [styles.selected]: isSelected,
        })}
        data-dragselectable={false}
        onClick={() => handleReqBlockClick(reqBlock)}
      >
        <RequirementDocumentBlockMainRow
          reqBlock={reqBlock}
          buttonsClassName={styles.buttons}
          dragListeners={dragListeners}
          onAddNote={() => setIsAddingNote(state => !state)}
          onAddComment={() => setIsCommentPopoverOpen(true)}
          onAddImage={() => setIsAddingImage(state => !state)}
          onFocus={onFocus}
        />
        {showNote && (
          <RequirementDocumentBlockNoteRow
            isAddingNote={isAddingNote}
            note={reqBlock.note}
            onChangeAddingNote={setIsAddingNote}
            onChange={reqBlock.setNote}
          />
        )}
        {showImage && (
          <RequirementDocumentBlockImageRow
            src={reqBlock.imageUrl}
            closeBtnClassName={styles.buttons}
            onSrcChange={reqBlock.setImageUrl}
            onChangeAddingImage={setIsAddingImage}
          />
        )}
      </div>
    </EntityAnnotationListPopover>
  );
};

export default observer(RequirementsDocumentBlock);
