import { EPageName } from "@router/hooks/useAppNavigate";
import { IAnalysis } from "@store/Analysis/AnalysisStore";
import { ICustomUnit } from "@store/CustomUnitStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsStore";

import appStore from "../store/AppStore";
import { IAttachment } from "../store/AttachmentStore";
import { IBomTable } from "../store/BomTable/BomTableStore";
import { IComment } from "../store/CommentStore";
import { IPropertyDefinition } from "../store/PropertyDefinitionStore";
import { IPropertyInstance } from "../store/PropertyInstanceStore";
import { IReportBlock } from "../store/ReportBlockStore";
import { IReport } from "../store/ReportsStore";
import { IStatusDefinition } from "../store/StatusDefinitionStore";
import { IStatusInstance } from "../store/StatusInstanceStore";
import { ITableViewConfig } from "../store/TableViewConfigStore";

// Export functions for workspaces and various other objects.
// Currently working in router:
// - Workspace
// - Block
// - Report
// - Requirements Page
//
// TODO:
// - Property instance
// - Status instance
// - Attachment
// - Comment
// - Property definition
// - Status definition
// - Requirement block
// - Report block

export enum EEntityShortcut {
  PropertyInstance = "pid",
  Attachment = "aid",
}

export function getIdFromHash(hash: string): string {
  return hash.split("=")[1] || "";
}

export function getEntityTypeByHash(hash: string): EEntityShortcut | undefined {
  if (hash.startsWith(`#${EEntityShortcut.Attachment}`)) {
    return EEntityShortcut.Attachment;
  }

  if (hash.startsWith(`#${EEntityShortcut.PropertyInstance}`)) {
    return EEntityShortcut.PropertyInstance;
  }

  return undefined;
}

export function linkToWorkspace() {
  const workspace = appStore.workspaceModel;
  if (!workspace?.id) {
    return undefined;
  }

  const baseUrl = window.location.href.split("workspaces")[0];
  return `${baseUrl}workspaces/${workspace.id}`;
}

// region Report links

export function linkToReport(report: IReport) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/reports/${report.id}`;
}

export function linkToReportBlock(reportBlock: IReportBlock) {
  const report = appStore.workspaceModel?.reportsMap.get(reportBlock.parentReport);
  if (!report) {
    return undefined;
  }

  const reportUrl = linkToReport(report);
  if (!reportUrl) {
    return undefined;
  }

  return `${reportUrl}#id=${reportBlock.id}`;
}

// endregion

// region BOM Table links

export function linkToBomTable(bomTable: IBomTable) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/pdm/bom/${bomTable.id}`;
}

// endregion

// region Requirements links

export function linkToRequirementsPage(requirementsPage: IRequirementsPage) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/requirements/${requirementsPage.id}`;
}

export function linkToRequirementBlock(requirementBlock: IRequirementBlock) {
  const requirementsPageUrl = linkToRequirementsPage(requirementBlock?.parentPage);
  if (!requirementsPageUrl) {
    return undefined;
  }

  return `${requirementsPageUrl}#id=${requirementBlock.id}`;
}

// endregion

// region Analysis links

export function linkToCodeBlock(codeBlock: IAnalysis) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }
  return `${workspaceUrl}/analysis/${codeBlock.id}`;
}

// endregion

export function linkToCustomUnit(customUnit: ICustomUnit) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }
  return `${workspaceUrl}/modeling/${EPageName.CustomUnits}#id=${customUnit.id}`;
}

// region Modeling links
export function linkToTableViewConfig(tableView: ITableViewConfig) {
  if (!tableView) {
    return undefined;
  }

  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/modeling/table#id=${tableView.id}`;
}

export function linkToPropertyDefinition(propertyDefinition: IPropertyDefinition) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/modeling/properties#id=${propertyDefinition.id}`;
}

export function linkToStatusDefinition(statusDefinition: IStatusDefinition) {
  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/modeling/statuses#id=${statusDefinition.id}`;
}

// endregion

// region Block-level links
export function linkToBlock(blockId: string) {
  if (!blockId) {
    return undefined;
  }

  const workspaceUrl = linkToWorkspace();
  if (!workspaceUrl) {
    return undefined;
  }

  return `${workspaceUrl}/modeling/blocks/${blockId}`;
}

export function linkToPropertyInstance(propertyInstance: IPropertyInstance) {
  const blockUrl = linkToBlock(propertyInstance?.parentBlock.id);
  if (!blockUrl) {
    return undefined;
  }

  return `${blockUrl}#${EEntityShortcut.PropertyInstance}=${propertyInstance.id}`;
}

export function linkToStatusInstance(statusInstance: IStatusInstance) {
  if (!statusInstance?.parentBlock?.id) {
    return undefined;
  }
  const blockUrl = linkToBlock(statusInstance?.parentBlock.id);
  if (!blockUrl) {
    return undefined;
  }

  return `${blockUrl}#sid=${statusInstance.id}`;
}

export function linkToAttachment(attachment: IAttachment) {
  if (attachment?.block?.id) {
    const blockUrl = linkToBlock(attachment.block.id);
    if (!blockUrl) {
      return undefined;
    }
    return `${blockUrl}#${EEntityShortcut.Attachment}=${attachment.id}`;
  }
}

export function linkToComment(comment: IComment) {
  if (!comment?.parentId) {
    return undefined;
  }

  const blockUrl = linkToBlock(comment.parentId);
  if (!blockUrl) {
    return undefined;
  }

  return `${blockUrl}#cid=${comment.id}`;
}

// endregion
