import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import {
  Attachment,
  Attribute,
  BomColumn,
  BomTable,
  Import,
  Interface,
  ManualVerificationStatus,
  PartNumberSchema,
  PropertyDefinition,
  ReportBlock,
  RequirementBlock,
  RequirementsPage,
  RequirementValidationOperation,
  RequirementVerificationMethod,
  StatusDefinition,
  StatusInstance,
  StatusOption,
  SuccessCriteriaType,
  Workspace,
  WorkspaceModuleType,
} from "@rollup-api/models";
import { toAnalysisInputSnapshot, toAnalysisOutputSnapshot, toAnalysisSnapshot } from "@rollup-api/models/analysis";
import { mapDataSinkEntryToSnapshot, mapDataSinkToSnapshot } from "@rollup-api/models/data-sinks/data-sink.mappers";
import { DataSink, DataSinkEntry } from "@rollup-api/models/data-sinks/data-sink.model";
import { Favorite } from "@rollup-api/models/favorites/favorite.model";
import { RollupEditorType } from "@rollup-types/editor";
import { IAnalysisModuleSnapshotIn } from "@store/Analysis/AnalysisModuleStore";
import { IAnalysis, IAnalysisSnapshotIn } from "@store/Analysis/AnalysisStore";
import { IBlockSnapshotIn } from "@store/BlockStore";
import { IDataConnectionModuleSnapshotIn } from "@store/DataConnection/DataConnectionModuleStore";
import { IDataSinkEntrySnapshotIn } from "@store/DataConnection/DataSinkEntryStore";
import { IDataSinkSnapshotIn } from "@store/DataConnection/DataSinkStore";
import { IFavoriteSnapshotIn } from "@store/FavoriteStore";
import { IImportSnapshotIn } from "@store/ImportStore";
import { IPartNumberSchemaSnapshotIn } from "@store/PartNumberSchemaStore";
import { IPropertyInstanceSnapshotIn } from "@store/PropertyInstanceStore";
import { IReport } from "@store/ReportsStore";
import { IRequirementBlockSnapshotIn } from "@store/Requirements/RequirementBlockStore";
import { IRequirementsPageSnapshotIn } from "@store/Requirements/RequirementsStore";
import { IStatusDefinitionSnapshotIn } from "@store/StatusDefinitionStore";
import { IStatusInstanceSnapshotIn } from "@store/StatusInstanceStore";
import { IWorkspace, IWorkspaceSnapshotIn, WorkspaceStore } from "@store/WorkspaceStore";
import { convertDateStringsToTimestamp, convertTimestamp, createDefaultEnumAssigner, sortRefArrayByOrderIndex } from "@utilities";
import { rollupClient } from "src/core/api";

import { addEntriesToParent, toMapSnapshot } from "./services.utils";

export interface IWorkspaceListItem {
  id: string;
  label: string;
  updatedAt: string;
  createdAt: string;
  createdBy?: string;
}

export interface IRevisionListItem {
  id: string;
  workspaceId: string;
  label: string;
  description?: string;
  createdBy: string;
  numBlocks: number;
  numProperties: number;
  numRequirementsPages: number;
  automated: boolean;
  createdAt: number;
}

export class WorkspaceService {
  static async SetRecentWorkspace(workspaceId: string) {
    const message = "Error setting most recent workspace";

    try {
      const res = await rollupClient.profiles.setWorkspace(workspaceId);
      return res?.data.mostRecentWorkspace === workspaceId;
    } catch (error) {
      console.warn(message, error);
      showApiErrorToast(message, error as Error);
      return false;
    }
  }

  // TODO remove unused field
  static async ClearRecentWorkspace() {
    try {
      const res = await rollupClient.profiles.clearWorkspace();
      return res?.status === 200;
    } catch (error) {
      console.warn("Error clearing most recent workspace", error);
      return false;
    }
  }

  static async CreateWorkspace(label: string): Promise<IWorkspace | undefined> {
    const message = "Error creating workspace";

    try {
      const res = await rollupClient.workspaces.create({ label });

      if (!res.data || res.status !== 201) {
        console.warn(message);
        showApiErrorToast(message);
        return undefined;
      }
      const workspace = WorkspaceService.PrepareWorkspace(res.data);
      if (!workspace) {
        console.warn(message);
        showApiErrorToast(message);
        return undefined;
      }
      return WorkspaceStore.create(workspace);
    } catch (error) {
      console.warn(message, error);
      showApiErrorToast(message, error as Error);
      return undefined;
    }
  }

  static async ImportWorkspace(data: any, modules?: WorkspaceModuleType[]): Promise<IWorkspace | undefined> {
    const res = await rollupClient.workspaces.import(data);
    if (res.status === 201 && res.data.id) {
      return WorkspaceService.FetchWorkspace(res.data.id, modules);
    } else {
      showApiErrorToast("Error fetching workspace");
      return undefined;
    }
  }

  static async ListWorkspaces(): Promise<IWorkspaceListItem[] | undefined> {
    const message = "Error listing workspaces";

    try {
      const { data: workspaces, status, statusText } = await rollupClient.workspaces.retrieveList();
      if (status !== 200 && status !== 201) {
        console.warn(message, statusText);
        showApiErrorToast(message, new Error(statusText));
        return undefined;
      }
      return workspaces ?? [];
    } catch (error) {
      console.warn(message, error);
      showApiErrorToast(message, error as Error);
      return undefined;
    }
  }

  static async FetchWorkspace(workspaceId: string, modules?: WorkspaceModuleType[]): Promise<IWorkspace | undefined> {
    try {
      const res = await rollupClient.workspaces.retrieve(workspaceId, false, modules?.join(","));

      if (!res.data || res.status !== 200) {
        console.error(`Error fetching workspace ${workspaceId}`);
        return undefined;
      }
      const workspaceSnapshot = WorkspaceService.PrepareWorkspace(res.data);
      if (!workspaceSnapshot) {
        console.error("Error creating workspace");
        return undefined;
      }
      const workspace = WorkspaceStore.create(workspaceSnapshot as IWorkspaceSnapshotIn);
      return workspace;
    } catch (error) {
      console.error(`Error fetching workspace ${workspaceId}`, error);
      return undefined;
    }
  }

  static async FetchAttachmentList(workspaceId: string): Promise<Attachment[] | undefined> {
    try {
      const { data, status } = await rollupClient.attachments.retrieveList();
      if (status !== 200) {
        console.warn(`Error fetching attachments for workspace ${workspaceId}`);
        return undefined;
      }
      return data;
    } catch (error) {
      console.warn(`Error fetching attachments for workspace ${workspaceId}`, error);
      return undefined;
    }
  }

  static async FetchRevisionList(workspaceId: string): Promise<IRevisionListItem[] | undefined> {
    try {
      const { data, status } = await rollupClient.revisions.retrieveList(workspaceId);
      if (status !== 200) {
        console.warn(`Error fetching revisions for workspace ${workspaceId}`);
        return undefined;
      }
      return data.map(r => ({ ...r, workspaceId, createdAt: convertTimestamp(r.createdAt) }));
    } catch (error) {
      console.warn(`Error fetching revisions for workspace ${workspaceId}`, error);
      return undefined;
    }
  }

  static async ApplyRevision(revisionId: string) {
    try {
      const res = await rollupClient.revisions.restore(revisionId);
      return res.status === 200;
    } catch (error) {
      console.warn(`Error applying revision ${revisionId}`, error);
      return false;
    }
  }

  static async CreateRevision(workspaceId: string, label: string, description?: string) {
    try {
      const res = await rollupClient.revisions.create(workspaceId, label, description);
      return res.status === 201;
    } catch (error) {
      console.warn(`Error creating revision  for workspace ${workspaceId}`, error);
      return false;
    }
  }

  static async DeleteRevision(revisionId: string) {
    try {
      const res = await rollupClient.revisions.delete(revisionId);
      return res.status === 200;
    } catch (error) {
      console.warn(`Error deleting revision ${revisionId}`, error);
      return false;
    }
  }

  // Below method is defined but not used, so commenting out for now.
  // private static ConstructDefaultBlockMap() {
  //   const blockMap: { [key: string]: any } = {};
  //   const blockId = uuidv4();
  //   blockMap[blockId] = {
  //     id: blockId,
  //     label: "New System",
  //     description: "",
  //     properties: null,
  //   };
  //   return { blockMap, blockId };
  // }

  private static PrepareWorkspace(input: Workspace): IWorkspaceSnapshotIn {
    if (!input?.id) {
      throw new Error("Workspace ID is required");
    }

    // Skip null values and do some data transformations
    const workspace = omitBy(input, isNull) as Workspace;

    const workspaceSnapshot: IWorkspaceSnapshotIn = {
      id: workspace.id,
      label: workspace.label,
      createdAt: convertTimestamp(workspace.updatedAt),
      updatedAt: convertTimestamp(workspace.createdAt),
      rootBlock: workspace.rootBlock,
    };

    if (workspace.blocks?.length) {
      workspaceSnapshot.blockMap = {};
      for (const { iconData, ...restBlock } of workspace.blocks) {
        if (restBlock?.id) {
          workspaceSnapshot.blockMap[restBlock.id] = omitBy(
            {
              ...restBlock,
              icon: iconData,
              statusInstances: [],
              propertyInstances: [],
            },
            isNull
          );
        }
      }

      // Generate child arrays from parent IDs
      for (const block of workspace.blocks) {
        if (block?.parentBlock) {
          const parent = workspaceSnapshot.blockMap[block.parentBlock];
          if (parent) {
            if (!Array.isArray(parent.children)) {
              parent.children = [];
            }
            if (!parent.children.includes(block.id)) {
              parent.children.push(block.id);
            }
          }
        }
      }
    } else {
      throw new Error("No blocks found in workspace");
    }

    if (!workspaceSnapshot.rootBlock) {
      throw new Error("No root block found in workspace");
    }

    if (workspace.propertyDefinitions?.length) {
      workspaceSnapshot.propertyDefinitionMap = {};
      for (const propertyDefinition of workspace.propertyDefinitions) {
        if (propertyDefinition?.id) {
          workspaceSnapshot.propertyDefinitionMap[propertyDefinition.id] = omitBy(propertyDefinition, isNull) as PropertyDefinition;
        }
      }
    }

    const dataSourceMap: IDataConnectionModuleSnapshotIn["dataSourceMap"] = {};
    if (workspace.dataSources) {
      for (const dataSource of workspace.dataSources) {
        if (dataSource?.id) {
          dataSourceMap[dataSource.id] = omitBy(dataSource, isNull) as any;
        }
      }
    }

    const dataSourceLinkMap: IDataConnectionModuleSnapshotIn["dataSourceLinkMap"] = {};
    if (workspace.dataLinks) {
      for (const dataLink of workspace.dataLinks) {
        if (dataLink?.id) {
          dataSourceLinkMap[dataLink.id] = omitBy(dataLink, isNull) as any;
        }
      }
    }

    const dataSinkMap = toMapSnapshot<DataSink, IDataSinkSnapshotIn>(workspace.dataSinks, mapDataSinkToSnapshot);
    const dataSinkEntryMap = toMapSnapshot<DataSinkEntry, IDataSinkEntrySnapshotIn>(workspace.dataSinkEntries, mapDataSinkEntryToSnapshot);
    addEntriesToParent<DataSinkEntry, IDataSinkSnapshotIn>(workspace.dataSinkEntries, "dataSinkId", dataSinkMap, "entries");

    workspaceSnapshot.dataConnection = { dataSourceMap, dataSourceLinkMap, dataSinkMap, dataSinkEntryMap };

    workspaceSnapshot.favorites = { favoritesMap: toMapSnapshot<Favorite, IFavoriteSnapshotIn>(workspace.favorites) };

    if (workspace.propertyInstances?.length) {
      workspaceSnapshot.propertyInstanceMap = {};
      for (const propertyInstance of workspace.propertyInstances) {
        const propertyInstanceSnapshot = omitBy(propertyInstance, isNull) as IPropertyInstanceSnapshotIn;
        if (!propertyInstanceSnapshot.parentBlock) {
          continue;
        }
        const parent = workspaceSnapshot.blockMap[propertyInstanceSnapshot.parentBlock];
        if (parent) {
          if (!parent.propertyInstances.includes(propertyInstanceSnapshot.id)) {
            parent.propertyInstances.push(propertyInstanceSnapshot.id);
          }
          workspaceSnapshot.propertyInstanceMap[propertyInstance.id] = propertyInstanceSnapshot;
        }
      }
    }

    workspaceSnapshot.importsMap = toMapSnapshot<Import, IImportSnapshotIn>(workspace.imports);
    workspaceSnapshot.statusDefinitionMap = toMapSnapshot<StatusDefinition, IStatusDefinitionSnapshotIn>(workspace.statusDefinitions);
    workspaceSnapshot.partNumberSchemas = toMapSnapshot<PartNumberSchema, IPartNumberSchemaSnapshotIn>(workspace.partNumberSchemas);

    if (workspace.attachments?.length) {
      workspaceSnapshot.attachments = {};
      workspaceSnapshot.attachments.attachmentMap = {};
      for (let attachment of workspace.attachments) {
        attachment = omitBy(attachment, isNull) as Attachment;

        if (attachment.block) {
          const parentBlock = workspaceSnapshot.blockMap[attachment.block];

          if (parentBlock) {
            if (!Array.isArray(parentBlock.attachments)) {
              parentBlock.attachments = [];
            }
            if (!parentBlock.attachments.includes(attachment.id)) {
              parentBlock.attachments.push(attachment.id);
            }
          }
        }

        // Populate the annotations for each attachment
        if (attachment.annotations) {
          for (const annotation of attachment.annotations) {
            annotation.recreated = true;
            annotation.createdAt = convertTimestamp(annotation.createdAt);
            annotation.updatedAt = convertTimestamp(annotation.updatedAt);
            annotation.view = { ...annotation.view, cloudFileId: attachment.id };
          }
        }

        workspaceSnapshot.attachments.attachmentMap[attachment.id] = convertDateStringsToTimestamp(attachment);
      }
    }

    if (workspace.requirementsPages?.length) {
      workspaceSnapshot.requirementsModule = {};
      workspaceSnapshot.requirementsModule.requirementsPageMap = {};
      for (const page of workspace.requirementsPages) {
        if (page?.id) {
          const requirementPage = omitBy(page, isNull) as RequirementsPage;
          workspaceSnapshot.requirementsModule.requirementsPageMap[page.id] = convertDateStringsToTimestamp(requirementPage);
        }
      }
    }

    if (workspace.requirementBlocks?.length) {
      const assignVerificationStatus = createDefaultEnumAssigner(ManualVerificationStatus, ManualVerificationStatus.Undefined);
      const assignRequirementValidationOperation = createDefaultEnumAssigner(
        RequirementValidationOperation,
        RequirementValidationOperation.Equals
      );
      const assignRequirementVerificationMethod = createDefaultEnumAssigner(
        RequirementVerificationMethod,
        RequirementVerificationMethod.Sample
      );
      const assignRequirementsSuccessCriteriaType = createDefaultEnumAssigner(SuccessCriteriaType, SuccessCriteriaType.Automatic);

      workspaceSnapshot.requirementsModule = workspaceSnapshot.requirementsModule ?? {};
      workspaceSnapshot.requirementsModule.requirementBlockMap = {};
      for (const reqBlock of workspace.requirementBlocks) {
        if (reqBlock?.id) {
          const currentBlock = omitBy<RequirementBlock>(reqBlock, isNull);
          const verificationMethods = currentBlock.verificationMethods;

          const parsedBlock = {
            ...currentBlock,
            parentPage: currentBlock.parentPage,
            // Correct for enum type changes
            verificationMethods: verificationMethods?.map(assignRequirementVerificationMethod),
            validationOperation: assignRequirementValidationOperation(currentBlock.validationOperation),
            manualVerification: assignVerificationStatus(currentBlock.manualVerification),
            successCriteria: assignRequirementsSuccessCriteriaType(currentBlock.successCriteria),
          } as IRequirementBlockSnapshotIn;

          workspaceSnapshot.requirementsModule.requirementBlockMap[reqBlock.id] = parsedBlock;
          if (parsedBlock.parentPage) {
            const parentPage = workspaceSnapshot.requirementsModule?.requirementsPageMap?.[parsedBlock.parentPage];
            if (parentPage) {
              if (!Array.isArray(parentPage.requirementBlocks)) {
                parentPage.requirementBlocks = [];
              }
              if (!parentPage.requirementBlocks.includes(parsedBlock.id)) {
                // Get around the readonly limitation of MST snapshot
                parentPage.requirementBlocks = [...parentPage.requirementBlocks, parsedBlock.id];
              }
            }
          }
        }
      }
    }

    const rootReportIds = [];
    if (workspace.reports?.length) {
      workspaceSnapshot.reportsMap = {};
      for (const report of workspace.reports) {
        if (report?.id) {
          workspaceSnapshot.reportsMap[report.id] = {
            ...report,
            parentReport: report.parentReportId,
            updatedAt: convertTimestamp(report.updatedAt),
          };
        }
      }

      // Generate report child arrays from report parent IDs
      for (const report of workspace.reports) {
        if (report.parentReportId) {
          const parentReport = workspaceSnapshot.reportsMap[report.parentReportId];
          if (parentReport) {
            if (!Array.isArray(parentReport.children)) {
              parentReport.children = [];
            }
            if (!parentReport.children.includes(report.id)) {
              (parentReport.children as string[]).push(report.id);
            }
          }
        } else {
          rootReportIds.push(report.id);
        }
      }
    }

    if (workspace.reportBlocks?.length) {
      workspaceSnapshot.reportBlocksMap = {};
      const filteredReportBlocks = workspace.reportBlocks.filter(block => block.parentReport);
      for (const repBlock of filteredReportBlocks) {
        if (repBlock?.id) {
          const currentBlock = omitBy(repBlock, isNull) as ReportBlock;
          // Handle deprecated header type (H4 -> H3) and invalid types
          if (currentBlock.type === "H4") {
            currentBlock.type = RollupEditorType.h3;
          } else if (!currentBlock.type || !Object.keys(RollupEditorType).includes(currentBlock.type)) {
            currentBlock.type = RollupEditorType.p;
          }
          // Attach to parent report
          if (currentBlock.parentReport) {
            const parentReport = workspaceSnapshot.reportsMap?.[currentBlock.parentReport];
            if (parentReport) {
              if (!Array.isArray(parentReport.reportBlocks)) {
                parentReport.reportBlocks = [];
              }
              if (!parentReport.reportBlocks.includes(currentBlock.id)) {
                (parentReport.reportBlocks as string[]).push(currentBlock.id);
              }
            }
          }
          workspaceSnapshot.reportBlocksMap[repBlock.id] = currentBlock;
        }
      }
    }

    if (workspace.bomTables?.length) {
      workspaceSnapshot.bomTablesMap = {};
      for (const bomTable of workspace.bomTables) {
        if (bomTable?.id) {
          const noNullsBomTable = omitBy(bomTable, isNull) as BomTable;
          workspaceSnapshot.bomTablesMap[bomTable.id] = { ...noNullsBomTable, updatedAt: convertTimestamp(noNullsBomTable.updatedAt) };
          if (bomTable.columns) {
            const columnMap: Record<string, any> = {};
            workspaceSnapshot.bomTablesMap[bomTable.id].columnArray = bomTable.columns.map(column => column.id) || [];
            for (const column of bomTable.columns) {
              if (column?.id) {
                columnMap[column.id] = {
                  ...omitBy(column, isNull),
                  table: bomTable.id,
                  propertyDefinition: column.propertyDefinitionId,
                  statusDefinition: column.statusDefinitionId,
                } as BomColumn;
              }
            }
            workspaceSnapshot.bomTablesMap[bomTable.id].columnMap = columnMap;
          }
        }
      }
    }

    if (workspace.statusInstances?.length) {
      workspaceSnapshot.statusInstanceMap = toMapSnapshot<StatusInstance, IStatusInstanceSnapshotIn>(workspace.statusInstances);
      const blockStatuses = workspace.statusInstances.filter(status => status.parentBlock);
      const requirementStatuses = workspace.statusInstances.filter(status => status.parentRequirementId);
      addEntriesToParent<StatusInstance, IBlockSnapshotIn>(blockStatuses, "parentBlock", workspaceSnapshot.blockMap, "statusInstances");

      if (workspaceSnapshot.requirementsModule?.requirementBlockMap) {
        addEntriesToParent<StatusInstance, IRequirementBlockSnapshotIn>(
          requirementStatuses,
          "parentRequirementId",
          workspaceSnapshot.requirementsModule.requirementBlockMap,
          "statusInstances"
        );
      }
    }

    if (workspace.statusOptions?.length) {
      workspaceSnapshot.statusOptionMap = {};
      for (let statusOption of workspace.statusOptions) {
        statusOption = omitBy(statusOption, isNull) as StatusOption;
        const definition = statusOption.statusDefinition
          ? workspaceSnapshot.statusDefinitionMap?.[statusOption.statusDefinition]
          : undefined;

        if (definition) {
          if (!Array.isArray(definition.statusOptions)) {
            definition.statusOptions = [];
          }
          if (!definition.statusOptions.includes(statusOption.id)) {
            definition.statusOptions.push(statusOption.id);
          }
          workspaceSnapshot.statusOptionMap[statusOption.id] = statusOption;
        }
      }
    }

    if (workspace.interfaces?.length) {
      workspaceSnapshot.interfaceMap = {};
      for (let currentInterface of workspace.interfaces) {
        currentInterface = omitBy(currentInterface, isNull) as Interface;

        if (currentInterface.parentBlock) {
          const parent = workspaceSnapshot.blockMap[currentInterface.parentBlock];

          if (parent) {
            if (!Array.isArray(parent.interfaces)) {
              parent.interfaces = [];
            }
            if (!parent.interfaces.includes(currentInterface.id)) {
              parent.interfaces.push(currentInterface.id);
            }
            workspaceSnapshot.interfaceMap[currentInterface.id] = currentInterface;
          }
        }
      }
    }

    if (workspace.attributes?.length) {
      workspaceSnapshot.attributeMap = {};
      for (let attribute of workspace.attributes) {
        attribute = omitBy(attribute, isNull) as Attribute;

        if (attribute) {
          // To get around MST snapshot readonly typings
          const parent = workspaceSnapshot.interfaceMap?.[attribute.parentInterface] as Interface & {
            attributes?: string[];
          };

          if (parent) {
            if (!Array.isArray(parent.attributes)) {
              parent.attributes = [];
            }
            if (!parent.attributes.includes(attribute.id)) {
              parent.attributes.push(attribute.id);
            }
            workspaceSnapshot.attributeMap[attribute.id] = attribute;
          }
        }
      }
    }

    const hasAnalysisData = workspace.codeBlocks?.length || workspace.analysisInputs?.length || workspace.analysisOutputs?.length;

    if (hasAnalysisData) {
      const analysisSnapshot: IAnalysisModuleSnapshotIn = {};
      analysisSnapshot.analysisMap = {};
      analysisSnapshot.analysisInputMap = {};
      analysisSnapshot.analysisOutputMap = {};

      if (workspace.codeBlocks?.length) {
        for (const codeBlock of workspace.codeBlocks) {
          if (codeBlock?.id) {
            analysisSnapshot.analysisMap[codeBlock.id] = toAnalysisSnapshot(codeBlock);
          }
        }
      }
      if (workspace.analysisInputs) {
        for (const analysisInput of workspace.analysisInputs) {
          if (analysisInput?.analysisId && analysisInput.id) {
            const snapshot = toAnalysisInputSnapshot(analysisInput);
            const codeBlock = analysisSnapshot.analysisMap[analysisInput.analysisId] as IAnalysisSnapshotIn & { inputs?: string[] };
            codeBlock.inputs = codeBlock.inputs ?? [];
            codeBlock.inputs.push(analysisInput.id);
            analysisSnapshot.analysisInputMap[analysisInput.id] = snapshot;
          }
        }
      }
      if (workspace.analysisOutputs) {
        for (const analysisOutput of workspace.analysisOutputs) {
          if (analysisOutput?.analysisId && analysisOutput.id) {
            const snapshot = toAnalysisOutputSnapshot(analysisOutput);
            const codeBlock = analysisSnapshot.analysisMap[analysisOutput.analysisId] as IAnalysisSnapshotIn & { outputs?: string[] };
            codeBlock.outputs = codeBlock.outputs ?? [];
            codeBlock.outputs.push(analysisOutput.id);
            analysisSnapshot.analysisOutputMap[analysisOutput.id] = snapshot;
          }
        }
      }
      workspaceSnapshot.analysis = analysisSnapshot;
    }

    // Sort entities by order index
    if (workspaceSnapshot.blockMap) {
      for (const id in workspaceSnapshot.blockMap) {
        const parent = workspaceSnapshot.blockMap[id];
        if (parent) {
          sortRefArrayByOrderIndex(parent.propertyInstances, workspaceSnapshot.propertyInstanceMap);
          sortRefArrayByOrderIndex(parent.children, workspaceSnapshot.blockMap);
          sortRefArrayByOrderIndex(parent.attachments, workspaceSnapshot.attachments?.attachmentMap);
        }
      }
    }

    if (workspaceSnapshot.reportsMap) {
      for (const id in workspaceSnapshot.reportsMap) {
        // To get around MST snapshot readonly typings
        const parent = workspaceSnapshot.reportsMap[id] as IReport;
        if (parent) {
          sortRefArrayByOrderIndex(parent.reportBlocks, workspaceSnapshot.reportBlocksMap);
          sortRefArrayByOrderIndex(parent.children, workspaceSnapshot.reportsMap);
        }
      }
      sortRefArrayByOrderIndex(rootReportIds, workspaceSnapshot.reportsMap);
      workspaceSnapshot.rootReportIds = rootReportIds;
    }

    if (workspaceSnapshot.requirementsModule?.requirementsPageMap) {
      for (const id in workspaceSnapshot.requirementsModule?.requirementsPageMap) {
        const parent = workspaceSnapshot.requirementsModule.requirementsPageMap[id] as IRequirementsPageSnapshotIn;
        if (parent?.requirementBlocks && workspaceSnapshot.requirementsModule.requirementBlockMap) {
          const mutableArray = Array.from(parent.requirementBlocks);
          parent.requirementBlocks = sortRefArrayByOrderIndex(mutableArray, workspaceSnapshot.requirementsModule.requirementBlockMap);
        }
      }
    }

    if (workspaceSnapshot.analysis?.analysisMap) {
      for (const id in workspaceSnapshot.analysis.analysisMap) {
        const parent = workspaceSnapshot.analysis.analysisMap[id] as IAnalysis;
        if (parent) {
          sortRefArrayByOrderIndex(parent.inputs, workspaceSnapshot.analysis.analysisInputMap);
        }
      }
    }

    return workspaceSnapshot;
  }
}
