import { Intent } from "@blueprintjs/core";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import {
  AttributeUpdateDto,
  InterfaceUpdateDto,
  PartNumberSchemaUpdateDto,
  PropertyDefinitionUpdateDto,
  PropertyInstanceUpdateDto,
  RequirementBlockUpdateDto,
  RequirementsPageUpdateDto,
  UpdateAnnotationDto,
  UpdateAttachmentDto,
  WorkspaceUpdateDto,
} from "@rollup-api/models";
import { ExecutionResult, UpdateAnalysisDto, UpdateAnalysisInputDto, UpdateAnalysisOutputDto } from "@rollup-api/models/analysis";
import { IUpdateCustomUnitDto } from "@rollup-api/models/customUnits";
import { ExecutionStatus } from "@rollup-api/models/execution-environments";
import { RequirementsSettingsModel } from "@rollup-api/models/workspace-settings/requirementSettings.model";
import appStore from "@store/AppStore";
import { EntityType } from "@store/types";
import { rollupClient } from "src/core/api";

import { BlockUpdateDto } from "../models/block";
import { UpdateBomTableDto } from "../models/bom";

export const updateAttribute = (id: string, attributeDto: AttributeUpdateDto) => {
  rollupClient.attributes.update(id, attributeDto).catch((err: Error) => {
    showApiErrorToast("Error updating attribute", err);
  });
};

export const updateAttachment = (id: string, attachmentDto: UpdateAttachmentDto) => {
  rollupClient.attachments.update(id, attachmentDto).catch((err: Error) => {
    showApiErrorToast("Error updating attachment", err);
  });
};

export const updateAnnotation = (id: string, annotationText: UpdateAnnotationDto) => {
  rollupClient.annotations.update(id, annotationText).catch((err: Error) => {
    showApiErrorToast("Error updating annotation", err);
  });
};

export const deleteAnnotation = (id: string) => {
  rollupClient.annotations.delete(id).catch((err: Error) => {
    showApiErrorToast("Error deleting annotation", err);
  });
};

export const updateRequirementsPage = (id: string, requirementsPageDto: RequirementsPageUpdateDto) => {
  rollupClient.requirementsPages.update(id, requirementsPageDto).catch((err: Error) => {
    showApiErrorToast("Error updating requirements page", err);
  });
};

export const updateRequirementBlock = (id: string, requirementsBlockDto: RequirementBlockUpdateDto) => {
  rollupClient.requirementBlocks.update(id, requirementsBlockDto).catch((err: Error) => {
    showApiErrorToast("Error updating requirements block", err);
  });
};

export const updateRequirementsSettings = (dto: RequirementsSettingsModel) => {
  rollupClient.workspaceSettings.update(EntityType.RequirementsDocument, dto).catch((err: Error) => {
    showApiErrorToast("Error updating requirements settings", err);
  });
};

export const lockRequirementBlock = (id: string) => {
  rollupClient.requirementBlocks.lock(id).catch((err: Error) => {
    showApiErrorToast("Error locking requirement block", err);
  });
};

export const unlockRequirementBlock = (id: string) => {
  rollupClient.requirementBlocks.unlock(id).catch((err: Error) => {
    showApiErrorToast("Error unlocking requirement block", err);
  });
};

export const updatePropertyInstance = (id: string, propertyInstanceDto: PropertyInstanceUpdateDto) => {
  rollupClient.modelingModule.propertyInstances.update(id, propertyInstanceDto).catch((err: Error) => {
    showApiErrorToast("Error updating property instance", err);
  });
};

export const lockPropertyInstance = (id: string) => {
  rollupClient.modelingModule.propertyInstances.lock(id).catch((err: Error) => {
    showApiErrorToast("Error locking property instance", err);
  });
};

export const unlockPropertyInstance = (id: string) => {
  rollupClient.modelingModule.propertyInstances.unlock(id).catch((err: Error) => {
    showApiErrorToast("Error unlocking property instance", err);
  });
};

export const updatePropertyDefinition = async (id: string, propertyDefinitionDto: PropertyDefinitionUpdateDto) => {
  return rollupClient.modelingModule.propertyDefinitions.update(id, propertyDefinitionDto).catch((err: Error) => {
    showApiErrorToast("Error updating property definition", err);
  });
};

export const updateCustomUnit = async (id: string, customUnitDto: IUpdateCustomUnitDto) => {
  return rollupClient.modelingModule.customUnits.update(id, customUnitDto).catch((err: Error) => {
    showApiErrorToast("Error updating custom unit", err);
  });
};

export const updateInterface = (id: string, interfaceDto: InterfaceUpdateDto) => {
  rollupClient.interfaces.update(id, interfaceDto).catch((err: Error) => {
    showApiErrorToast("Error updating interface", err);
  });
};

export const updateBlock = (id: string, blockDto: BlockUpdateDto) => {
  return rollupClient.modelingModule.blocks.update(id, blockDto).catch((err: Error) => {
    showApiErrorToast("Error updating block", err);
  });
};

export const reorderBlock = (srcId: string, destId: string) => {
  rollupClient.modelingModule.blocks.reorder(srcId, destId).catch((err: Error) => {
    showApiErrorToast("Error reordering block", err);
  });
};

export const reorderReport = (srcId: string, destId: string) => {
  rollupClient.reports.reorder(srcId, destId).catch((err: Error) => {
    showApiErrorToast("Error reordering page", err);
  });
};

export const setBlockPartNumber = (blockId: string, schemaId: string) => {
  return rollupClient.modelingModule.blocks.setPartNumber(blockId, schemaId).catch((err: Error) => {
    showApiErrorToast("Error setting block part number", err);
  });
};

export const reorderPropertyInstance = (srcId: string, destId: string, parentBlock?: string) => {
  rollupClient.modelingModule.propertyInstances.reorder(srcId, destId, parentBlock).catch((err: Error) => {
    showApiErrorToast("Error reordering property instance", err);
  });
};

export const reorderInterface = (srcId: string, destId: string) => {
  rollupClient.interfaces.reorder(srcId, destId).catch((err: Error) => {
    showApiErrorToast("Error reordering interface", err);
  });
};

export const updateWorkspace = (id: string, workspaceDto: WorkspaceUpdateDto) => {
  rollupClient.workspaces.update(id, workspaceDto).catch((err: Error) => {
    showApiErrorToast("Error updating workspace", err);
  });
};

export const updatePartNumberSchema = (id: string, partNumberSchemaUpdateDto: PartNumberSchemaUpdateDto) => {
  rollupClient.partNumberSchemas.update(id, partNumberSchemaUpdateDto).catch((err: Error) => {
    showApiErrorToast("Error updating id schema", err);
  });
};

export const updateBomTable = (id: string, bomTableDto: UpdateBomTableDto) => {
  rollupClient.bomTables.update(id, bomTableDto).catch((err: Error) => {
    showApiErrorToast("Error updating BOM table", err);
  });
};

export const updateAnalysis = async (id: string, dto: UpdateAnalysisDto, showConfirmation = false) => {
  try {
    await rollupClient.analysisModule.analyses.update(id, dto);
    if (showConfirmation) {
      showToast("Code block updated", Intent.SUCCESS);
    }
  } catch (err: any) {
    showApiErrorToast("Error updating code block", err);
  }
};

export const updateAnalysisInput = async (id: string, dto: UpdateAnalysisInputDto) => {
  try {
    await rollupClient.analysisModule.analysisInputs.update(id, dto);
  } catch (err: any) {
    showApiErrorToast("Error updating code input", err);
  }
};

export const updateAnalysisOutput = async (id: string, dto: UpdateAnalysisOutputDto) => {
  try {
    await rollupClient.analysisModule.analysisOutputs.update(id, dto);
  } catch (err: any) {
    showApiErrorToast("Error updating code output", err);
  }
};

export const updateExecutionResult = (analysisId: string, result: ExecutionResult) => {
  if (!analysisId || !result.id) {
    return false;
  }

  const codeBlock = appStore.workspaceModel?.analysis?.analysisMap.get(analysisId);
  if (!codeBlock) {
    return false;
  }

  codeBlock.setLatestExecution(result);
  updateAnalysisOutputs(result);
  return true;
};

export const updateAnalysisOutputs = (result: ExecutionResult) => {
  if (result?.outputs && appStore.workspaceModel?.analysis?.analysisOutputMap?.size) {
    for (const executionOutput of result.outputs) {
      if (executionOutput?.id) {
        const analysisOutput = appStore.workspaceModel.analysis.analysisOutputMap.get(executionOutput.id);
        if (analysisOutput) {
          if (typeof executionOutput.value === "number") {
            analysisOutput.setValue(executionOutput.value.toString());
          } else {
            analysisOutput.clearValue();
          }
        }
      }
      if (executionOutput.id === "error") {
        showApiErrorToast("Error executing code block", new Error(JSON.stringify(executionOutput)));
      }
    }
  }
};

export const runCodeBlock = async (id: string) => {
  try {
    console.debug(`Executing code block ${id}`);
    const res = await rollupClient.analysisModule.analyses.run(id);
    if (res.data?.status !== ExecutionStatus.Preparing) {
      showToast(`Code block could not be executed: ${res.data?.errorMessage ?? "Unknown error"}`, Intent.WARNING);
    }
    updateExecutionResult(id, res.data);
    return res.data;
  } catch (err: any) {
    showApiErrorToast("Error executing code block", err);
  }
  return undefined;
};
