import { useHandleDeleteDocument } from "@hooks/useHandleDeleteDocument";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { EntityContextMenu } from "@components/EntityContextMenu";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsStore";
import { EntityType } from "@store/types";

interface IRequirementsSidebarContextMenuProps {
  reqPage: IRequirementsPage;
  onEnter?(): void;
}

const RequirementsSidebarContextMenu = (props: IRequirementsSidebarContextMenuProps) => {
  const { reqPage, onEnter } = props;
  const workspace = useWorkspace();

  const handleDelete = useHandleDeleteDocument({
    document: reqPage,
    docArray: workspace.requirementsModule.values,
    pageName: EPageName.Requirements,
    deleteDocument: appStore.workspaceModel?.deleteRequirementsPage,
  });

  const handleDuplicate = () => {
    appStore.workspaceModel?.duplicateReqPage(reqPage.id);
  };

  const handleUpdate = (label: string) => {
    reqPage.setLabel(label);
  };

  return (
    <EntityContextMenu
      node={reqPage}
      entityType={EntityType.RequirementsDocument}
      defaultName={reqPage.label}
      defaultDocNumber={reqPage.docNumber}
      onNameChange={handleUpdate}
      onDocNumberChange={reqPage.setDocNumber}
      onDuplicate={handleDuplicate}
      onDelete={handleDelete}
      onEnter={onEnter}
      onEnterDocNumber={onEnter}
    />
  );
};

export default observer(RequirementsSidebarContextMenu);
