import { Divider } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import Text, { TextColor, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import appStore from "@store/AppStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";

import CodeBlocksSidebarItem from "../CodeBlocks/CodeBlocksSidebarItem/CodeBlocksSidebarItem";

import "./AnalysisSidebar.scss";

export interface AnalysisSidebarProps {
  minimal?: boolean;
}
const AnalysisSidebar = ({ minimal }: AnalysisSidebarProps) => {
  const workspace = useWorkspace();
  const codeBlocks = workspace.analysis.analyses;

  return (
    <div className="analysis-sidebar">
      <Text variant={TextVariant.Label} color={TextColor.Secondary}>
        Code Blocks
      </Text>
      {codeBlocks.toReversed().map(block => (
        <CodeBlocksSidebarItem key={block.id} codeBlock={block} minimal={minimal} />
      ))}
      {appStore.env.featureFlags.enabled(FeatureFlag.SPREADSHEET_SUPPORT) && (
        <>
          <Divider />
          <Text variant={TextVariant.Label} color={TextColor.Secondary}>
            Spreadsheets
          </Text>
          {/* {spreadsheets.toReversed().map(spreadsheet => (
            <SpreadsheetSidebarItem key={spreadsheet.id} spreadsheet={spreadsheet} minimal={minimal} />
          ))} */}
        </>
      )}
    </div>
  );
};

export default observer(AnalysisSidebar);
