import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { InputGroup, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { STATUS_MENU_ITEMS } from "@components/ProjectManagement/constants";
import appStore from "@store/AppStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";

import "./HeaderNav.scss";

interface IHeaderNavParams {
  statusDefinition?: IStatusDefinition;
  hideErase?: boolean;
  onDelete?: () => void;
  onHide?: () => void;
  onRemove?: () => void;
  onStatusTypeChange?: () => void;
}

const HeaderStatusNav = (props: IHeaderNavParams) => {
  const { statusDefinition, onDelete, onRemove, hideErase, onStatusTypeChange, onHide } = props;
  const [nameInputValue, setNameInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setNameInputValue(statusDefinition?.label ?? ""), [statusDefinition]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setNameInputValue(event.target.value);

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  const updateStatusDefinitionLabel = () => {
    const trimmedValue = nameInputValue.trim();

    if (trimmedValue) {
      statusDefinition?.setLabel(trimmedValue);
    } else {
      setNameInputValue(statusDefinition?.label ?? "");
    }
  };

  const handleClearAllStatusInstances = () => {
    if (statusDefinition) {
      appStore.workspaceModel?.clearStatusInstancesByDefinition(statusDefinition);
    }
  };

  const handleChangeStatusType = (statusType: StatusType) => {
    if (statusDefinition) {
      appStore.workspaceModel?.migrateStatusType(statusDefinition, statusType);
    }
    onStatusTypeChange?.();
  };

  const renderStatusTypeNav = () =>
    STATUS_MENU_ITEMS.map(status => (
      <MenuItem
        key={status.type}
        text={status.text}
        icon={status.icon}
        disabled={statusDefinition?.type === status.type}
        selected={statusDefinition?.type === status.type}
        onClick={() => handleChangeStatusType(status.type)}
        e2eIdentifiers={[status.type]}
      />
    ));

  const handleNameInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateStatusDefinitionLabel();
    }
  };

  return (
    <>
      <p className="header-nav--label">Name</p>
      <InputGroup
        className="header-nav--input"
        onKeyUp={handleNameInputKeyUp}
        inputRef={inputRef}
        onBlur={updateStatusDefinitionLabel}
        value={nameInputValue}
        autoFocus
        onFocus={handleInputFocus}
        onChange={handleInputChange}
      />
      <MenuDivider title="Data type" />
      {renderStatusTypeNav()}
      {onHide && <MenuItem icon="eye-off" text="Hide" onClick={onHide} e2eIdentifiers="hide" />}
      <MenuDivider />
      {onRemove && <MenuItem icon="th-disconnect" text="Remove column" onClick={onRemove} e2eIdentifiers="remove" />}
      {onDelete && <MenuItemDelete text="Delete definition" onDelete={onDelete} />}
      {!hideErase && <MenuItem icon="eraser" text="Clear All" onClick={handleClearAllStatusInstances} e2eIdentifiers="clear-all" />}
    </>
  );
};

export default observer(HeaderStatusNav);
