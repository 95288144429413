import { CustomCellRendererProps } from "ag-grid-react";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import StatusTypeCheckEditor from "@components/ProjectManagement/StatusTypeCheckEditor";
import StatusTypeDateEditor from "@components/ProjectManagement/StatusTypeDateEditor";
import StatusTypeNumberEditor from "@components/ProjectManagement/StatusTypeNumberEditor";
import StatusTypeTextEditor from "@components/ProjectManagement/StatusTypeTextEditor";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import StatusInstanceLinkCell from "../Cells/StatusInstanceLinkCell";
import StatusInstanceSelectCell from "../Cells/StatusInstanceSelectCell";
import UserSelectCell from "../Cells/UserSelectCell";

import styles from "./StatusCell.module.scss";

export interface IBlockStatusCellOwnProps {
  statusDefinition: IStatusDefinition;
}

interface IBlockStatusCellProps extends IBlockStatusCellOwnProps, CustomCellRendererProps<{ block: IBlock }> {}

const BlockStatusCell = (props: IBlockStatusCellProps) => {
  const { statusDefinition, data } = props;
  const block = data?.block;

  if (!block || !isAlive(block)) {
    return null;
  }

  const statusDefinitionProxy = appStore.workspaceModel?.statusDefinitions.find(i => i.id === statusDefinition?.id);

  if (!statusDefinitionProxy) {
    return null;
  }

  const statusInstance = block.validatedStatusInstances.find(i => i.statusDefinition?.id === statusDefinition.id);

  const handleOnAddStatusInstance = async (statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined> => {
    if (appStore.workspaceModel) {
      return appStore.workspaceModel.addStatusInstance(block, statusDefinition, value);
    }
  };

  const editorProps = {
    statusDefinition: statusDefinitionProxy,
    statusInstance,
    onAddStatusInstance: handleOnAddStatusInstance,
    onDeleteStatusInstance: block.deleteStatusInstance,
  };

  const renderComponent = () => {
    switch (statusDefinitionProxy.type) {
      case StatusType.mention:
        return <UserSelectCell {...editorProps} />;
      case StatusType.multiSelect:
      case StatusType.singleSelect:
        return <StatusInstanceSelectCell {...editorProps} />;
      case StatusType.number:
        return <StatusTypeNumberEditor hidePlaceholder {...editorProps} />;
      case StatusType.date:
        return <StatusTypeDateEditor hidePlaceholder {...editorProps} />;
      case StatusType.check:
        return <StatusTypeCheckEditor {...editorProps} />;
      case StatusType.url:
        return <StatusInstanceLinkCell {...editorProps} />;
      default:
        return <StatusTypeTextEditor hidePlaceholder {...editorProps} />;
    }
  };

  return (
    <div
      className={styles.statusCell}
      data-testid={`${block.label}_${editorProps.statusDefinition.label}_${editorProps.statusDefinition.id}`}
    >
      {renderComponent()}
    </div>
  );
};

export default observer(BlockStatusCell);
